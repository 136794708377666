/* consolas-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  unicode-range: U+000-5FF;
  src: local(''),
  url('fonts/consolas/Consolas.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/consolas/Consolas.woff') format('woff'), /* Modern Browsers */
  url('fonts/consolas/Consolas.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/consolas/Consolas.eot'); /* IE9 Compat Modes */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  unicode-range: U+000-5FF;
  src: local(''),
  url('fonts/montserrat/montserrat-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat/montserrat-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat/montserrat-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat/montserrat-v21-latin-regular.eot'); /* IE9 Compat Modes */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  unicode-range: U+000-5FF;
  src: local(''),
  url('fonts/montserrat/montserrat-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat/montserrat-v21-latin-600.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat/montserrat-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat/montserrat-v21-latin-600.eot'); /* IE9 Compat Modes */

}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  unicode-range: U+000-5FF;
  src: local(''),
  url('fonts/montserrat/montserrat-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat/montserrat-v21-latin-700.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat/montserrat-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat/montserrat-v21-latin-700.eot'); /* IE9 Compat Modes */
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2F1919;
  overflow-y: scroll;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 900ms ease-out, transform 500ms ease-in-out,
  visibility 900ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar
{
  width: 7px + ' !important';
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #5a2c36;
  opacity: 0.4;
}




/* Slide in */
li a {
  overflow: hidden;
}

li a::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}
a {
  display: block;
  position: relative;
  padding: 0.2em 0;
}

li a:hover::after,
li a:focus::after{
  transform: translate3d(0, 0, 0);
}

a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.11em;
  background-color: #DC9777;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

a:hover::after,
a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}
